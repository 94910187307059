
import {Doughnut} from 'vue-chartjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  extends: Doughnut,
  props: {
    startDate: String,
    endDate: String,
    customLabels: Array,
    customData: Array,
    customCount: Array,
    customBGColors: Array,
  },
  data() {
    return {
      expandedSector: null,
      options: {
        intersect: false,
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: true,
        cutoutPercentage: 55,
        onHover: this.handle,
        layout: {
          padding: {
            top: 10,
            right: 10,
            bottom: 10,
            left: 10,
          },
        },
        tooltips: {
          enabled: true,
          displayColors: true,
          callbacks: {
            titleFontSize: 24,
            position: 'nearest',
            label: function(tooltipItem, data) {
              return data.labels[tooltipItem.index] + ' : ' + data.datasets[0].percentage[tooltipItem.index] + ' (' + data.datasets[0].data[tooltipItem.index] + ')';
            },
            labelColor: function(tooltipItem, chart) {
              return {
                borderColor: chart.config.data.datasets[tooltipItem.datasetIndex].backgroundColor[tooltipItem.index],
                backgroundColor: chart.config.data.datasets[tooltipItem.datasetIndex].backgroundColor[tooltipItem.index],
              };
            },
            labelTextColor: function(tooltipItem, chart) {
              return 'white';
            },
          },
        },
        plugins: {
          datalabels: {
            enabled: true,
            textAlign: 'center',
            display: 'auto',
            color: 'white',
            font: {
              size: '14',
            },
            labels: {
              title: {
                font: {
                  weight: 'bold',
                },
              },
              value: {
                color: 'green',
              },
            },
            formatter: function(value, context) {
              const percentage = context.chart.data.datasets[0].percentage[context.dataIndex];
              if (percentage.substring(0, percentage.length - 1) > 5) {
                let title = context.chart.data.labels[context.dataIndex];
                if (title.length >= 9) {
                  title = title.substring(0, 8);
                  title += '...';
                }
                return [title, percentage];
              }
              return '';
            },
          },
        },
      },
    };
  },
  computed: {
    dateStart() {
      return this.startDate;
    },
    dateEnd() {
      return this.endDate;
    },
    DataLabels: function() {
      return this.customLabels;
    },
    ChartDataSets: function() {
      return this.customData;
    },
    ChartCounts: function() {
      return this.customCount;
    },
    ChartBGColors: function() {
      return this.customBGColors;
    },
  },
  watch: {
    dateStart: function() {
      this.showChart();
    },
    dateEnd: function() {
      this.showChart();
    },
  },
  methods: {
    expandChartSector(newItem, oldItem) {
      this.expandedSector = newItem;
      if (oldItem) {
        oldItem._model.outerRadius -= 4;
      }
      newItem._model.outerRadius += 4;
    },
    shrinkAllSectors() {
      (this.expandedSector)._model.outerRadius -= 4;
      this.expandedSector = null;
    },
    handle(point, event) {
      const item = event[0];
      if (item != undefined) {
        const newItem = item;
        const oldItem = this.expandedSector;
        this.expandChartSector(newItem, oldItem);

        const segmentIndex = item._index;
        const segmentDataValue = this.customData[segmentIndex];
        const sumTotal = this.customData.reduce((a, b) => a + b, 0);
        const percentage = (segmentDataValue / sumTotal) * 100;
        const roundedPercentage = Math.round((percentage + Number.EPSILON) * 100) / 100;
        roundedPercentage;
      } else {
        if (this.expandedSector != null) {
          this.shrinkAllSectors();
        }
      }
    },
    addChartPlugin: function() {
      this.addPlugin(
          {
            id: 'ChartDataLabels',
            beforeDraw: ChartDataLabels,
          },
      );
    },
    showChart: function() {
      if (this._chart) {
        this._chart.destroy();
      }
      this.renderChart(
          {
            labels: this.DataLabels,
            datasets: [{
              borderWidth: 1,
              backgroundColor: this.ChartBGColors,
              data: this.ChartCounts,
              percentage: this.ChartDataSets.map((x) => x.toString() + '%'),
            }],
          },
          this.options,
      );
    },
  },
  mounted() {
    this.addChartPlugin();
    this.showChart();
  },
};
